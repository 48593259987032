/* Ant overrides */
.ant-layout {
  background: #1f1f1f;
}
.markdown-content,
h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
h4.ant-typography,
h5.ant-typography,
h6.ant-typography,
p,
.ant-breadcrumb,
.ant-breadcrumb-link,
.ant-breadcrumb .ant-breadcrumb-separator {
  color: #ccc;
}
p,
ul,
ol {
  size: 16px;
  line-height: 25px;
}
p img + em {
  display: block;
  font-size: 12px;
}
.ant-tree .ant-tree-node-content-wrapper:hover,
.ant-tree .ant-tree-checkbox + span:hover {
  background: transparent;
}
.ant-breadcrumb a:hover {
  background: transparent;
}
.ant-modal .ant-modal-close {
  color: #ccc;
}
/* Link Anchor*/
.ant-anchor-wrapper .ant-anchor .ant-anchor-link-title {
  color: #8552b3;
}
.ant-anchor-wrapper .ant-anchor .ant-anchor-link-title:hover,
.ant-anchor-wrapper .ant-anchor .ant-anchor-link-active > .ant-anchor-link-title {
  color: #c788ff;
}
.ant-anchor-wrapper:not(.ant-anchor-wrapper-horizontal) .ant-anchor .ant-anchor-ink {
  background-color: #8552b3;
}
.ant-anchor .ant-anchor-ink {
  display: inline-block;
  background-color: #8552b3;
}
.ant-anchor-wrapper:not(.ant-anchor-wrapper-horizontal) .ant-anchor .ant-anchor-ink {
  display: inline-block;
  background-color: #8552b3;
  top: 15px;
  height: 22px;
}
/* Ant overrides - end */
/* Main */
main img {
  max-width: 641px;
  border: 2px solid #333;
}
main img[alt$=" - large"] {
  max-width: 100%;
}
.loading-text h1 {
  color: #ccc;
  font-size: 2.5em;
  font-weight: 600;
}
/* Main - end */
/* Left side anchor */
.ant-input-affix-wrapper {
  padding: 4px 11px;
  color: #ccc;
  border-radius: 0;
  border: 0;
  background-color: #2b2b2b;
  z-index: 1;
  box-shadow: 5px 5px 20px #111;
}
.ant-input {
  color: #ccc;
  background-color: #333;
  background-color: #2b2b2b;
}
.ant-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #555;
  opacity: 1;
  /* Firefox */
}
.ant-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #555;
}
.ant-input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #555;
}
.ant-tree-list {
  background-color: #333;
  box-shadow: 5px 5px 20px #111;
  color: #ccc;
}
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #333;
}
/* Left side anchor - end */
/* Right side anchor */
.right-side-anchor .ant-anchor-link {
  padding-block: 0 !important;
  line-height: 30px;
}
.right-side-anchor .ant-anchor-wrapper {
  padding: 0 !important;
  background-color: #333;
  box-shadow: 5px 5px 20px #111;
}
/* Right side anchor - end */
.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* App.less */
.layout {
  /* layout styles */
}
.page-content {
  padding: 20px 50px;
}
.filter-input {
  /* styles for the filter input go here */
}
.main-layout {
  padding: 24px;
  min-height: calc(100vh - 192px);
}
.main-content {
  padding: 0 24px;
  min-height: 280px;
}
.toc-title {
  margin-top: -10px;
}
.toc-list {
  /* styles for the table of contents go here */
}
.semi-bold {
  font-weight: 600;
  color: #ccc;
}
/* AppFooter styles */
.app-footer {
  background-color: #111;
  color: #ccc;
  font-size: 13px;
  padding: 24px 50px;
}
.app-footer h3 {
  color: #ccc !important;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px !important;
  margin-top: 0;
}
.app-footer .footer-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.app-footer .footer-menu li {
  margin: 5px 0;
  font-weight: 400;
  padding: 0 !important;
}
.app-footer .footer-menu li a {
  color: #ccc;
}
.app-footer .footer-menu li a:hover {
  color: #9e70c7 !important;
}
.app-footer .footer-icon {
  margin-right: 4px;
  width: 18px;
  margin-left: -18px;
}
.app-footer .footer-hr {
  border-color: #555 !important;
}
.app-footer p {
  margin: 0;
}
.app-footer a {
  color: #ccc;
}
.app-footer a:hover {
  color: #9e70c7 !important;
}
.app-footer .menu-col {
  margin-left: 4.16%;
}
.app-footer .footer-row {
  padding-bottom: 31px;
}
.app-footer img {
  max-width: 100%;
  margin: 0 auto;
  display: block;
}
.app-footer .esif-text {
  color: #ccc;
  display: block;
  font-size: 8px;
  line-height: 10px;
  margin: 5px 0 15px;
  text-align: justify;
}
.app-footer .footer-thumbnails {
  padding: 5px;
}
/* header styles */
.ant-layout-header {
  background-color: #1f1f1f;
  box-shadow: 0 5px 5px #111;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
  height: 64px;
}
.logo {
  display: flex;
  align-items: center;
  line-height: 64px;
}
.logo a {
  color: #ccc;
}
.logo a:hover {
  color: #9e70c7 !important;
}
.logo img {
  max-width: 100%;
  height: 50px;
}
.company-name {
  font-size: 20px;
  font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
  padding-left: 4px;
}
.logo-link {
  display: inline-flex;
  align-items: center;
}
/* Button styles */
.thumbs-button {
  background-color: #3f3f3f;
  color: #ccc !important;
  margin: 15px;
}
.thumbs-button.selected {
  background-color: #6a3f91;
  color: #ffffff;
}
.thumbs-button:hover {
  background-color: #8552b3;
}
/* Form CSS */
.ant-modal .ant-modal-content {
  width: 90%;
  margin: 0 auto;
  background-color: #1f1f1f;
}
.ant-modal .ant-modal-title {
  font-size: 1.5rem;
  background-color: #1f1f1f;
  color: #ccc;
}
.ant-form label {
  color: #ccc;
}
.ant-form-item .ant-form-item-label > label {
  color: #ccc;
  font-size: 1rem;
}
.checkbox-group {
  margin-top: -1em;
}
.checkbox-group ul {
  list-style-type: none;
  padding-left: 0;
}
.checkbox-group ul li {
  font-size: 1rem;
}
.ant-btn-primary {
  background-color: #6a3f91;
}
.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: #8552b3;
  border-color: #8552b3;
}
.ant-btn-default {
  background-color: #3f3f3f;
  color: #ccc;
  border-color: #3f3f3f;
}
.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: #4f4f4f;
  border-color: #4f4f4f;
  color: #ccc;
}
/*Breadcrumbs CSS*/
.ant-breadcrumb a {
  color: #8552b3;
  font-weight: 600;
  font-size: 1em;
}
.ant-breadcrumb a:hover {
  color: #c788ff;
  font-weight: 600;
  font-size: 1em;
}
.ant-breadcrumb .ant-breadcrumb-separator {
  font-weight: 600;
  font-size: 1em;
}
.home {
  background-color: #8552b3;
  mask-image: url('../public/icons/house-regular.svg');
  mask-repeat: no-repeat;
  line-height: 1.25rem;
  padding-left: 1.5rem;
}
/* Markdown Content Style */
.markdown-content h1 {
  font-size: 2.5em;
  font-weight: 600;
}
.markdown-content h2 {
  font-size: 2em;
  font-weight: 600;
}
.markdown-content h3 {
  font-size: 1.5em;
  font-weight: 600;
}
.markdown-content h4 {
  font-size: 1.2em;
  font-weight: 600;
}
.markdown-content pre code {
  white-space: break-spaces;
}
.markdown-content table {
  margin-bottom: 0.5em;
}
.markdown-content th {
  background-color: #252525;
}
.markdown-content th,
.markdown-content td {
  padding: 10px;
  border: 1px solid #333;
  margin-bottom: 0.5em;
}
.markdown-content .info {
  padding: 0 1em;
  margin-bottom: 16px;
  color: inherit;
  border-left: 0.25em solid #986ee2;
}
.markdown-content .info::before {
  color: #986ee2;
  background-color: #986ee2;
  mask-image: url('../public/icons/circle-info-regular.svg');
  content: "";
  mask-repeat: no-repeat;
  line-height: 1.25rem;
  padding-left: 1.5rem;
}
.markdown-content .info span:first-child {
  color: #986ee2;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.markdown-content .warning {
  padding: 0 1em;
  margin-bottom: 16px;
  color: inherit;
  border-left: 0.25em solid #c69026;
}
.markdown-content .warning::before {
  color: #c69026;
  background-color: #c69026;
  mask-image: url('../public/icons/triangle-exclamation-regular.svg');
  content: "";
  mask-repeat: no-repeat;
  line-height: 1.25rem;
  padding-left: 1.5rem;
}
.markdown-content .warning span:first-child {
  color: #c69026;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.markdown-content .critical {
  padding: 0 1em;
  margin-bottom: 16px;
  color: inherit;
  border-left: 0.25em solid #f44336;
}
.markdown-content .critical::before {
  color: #f44336;
  background-color: #f44336;
  mask-image: url('../public/icons/hexagon-exclamation-regular.svg');
  content: "";
  mask-repeat: no-repeat;
  line-height: 1.25rem;
  padding-left: 1.5rem;
}
.markdown-content .critical span:first-child {
  color: #f44336;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.react-syntax-highlighter-line-number {
  color: #666 !important;
}
.copy-code {
  float: right;
  position: relative;
  top: -50px;
  left: -10px;
}
.copy-code::before {
  color: #986ee2;
  background-color: #986ee2;
  mask-image: url('../public/icons/copy-regular.svg');
  mask-repeat: no-repeat;
  content: "";
  line-height: 1.25rem;
  padding-left: 1rem;
}
/* Tree Scroll */
.ant-tree {
  overflow-y: auto;
  max-height: 500px;
  scrollbar-width: thin;
  scrollbar-color: #8552b3 #3f3f3f;
  background: #2f2f2f;
  border-radius: 0;
  box-shadow: 5px 5px 20px #111;
}
.ant-tree a {
  color: #8552b3;
}
.ant-tree a:hover {
  color: #c788ff;
}
/* Body Style */
body {
  background: #1f1f1f;
}
pre {
  background-color: #333 !important;
  border-radius: 3px;
}
.ant-message .ant-message-notice .ant-message-notice-content {
  background-color: #1f1f1f;
  color: white;
}
a {
  color: #8552b3;
}
a:hover {
  color: #c788ff;
}
.feedback {
  color: #ccc;
  font-size: 1.5em;
  font-weight: 600;
}
.feedback-form em {
  display: block;
  color: #ccc;
  font-size: 10px;
  margin-bottom: 8px;
}
.future::after {
  font-weight: bold;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #ccc;
  content: "Future: ";
}
.future::before {
  color: #986ee2;
  background-color: #986ee2;
  mask-image: url('../public/icons/person-to-portal-regular.svg');
  mask-repeat: no-repeat;
  content: "";
  line-height: 1.25rem;
  padding-left: 1.5rem;
}
